/**
 * toggleActiveElement
 * Toggles "active" class on one item between many of the same
 * (like one li of multiples li's in an ul)
 */
const toggleActiveElement = (element) => {
  element.parentNode.childNodes.forEach((child) => {
    if (child instanceof HTMLElement) {
      child.classList.remove('active')
    }
  })

  element.classList.add('active')
}

jQuery(() => {
  document.querySelectorAll('#nav > li').forEach(item => {
    if (item.querySelector('ul')) {
      const icon = document.createElement('i')
      icon.classList.add('fa')
      icon.classList.add('fa-chevron-down')

      item.querySelector('a').insertBefore(icon, item.querySelector('a span'))
    }
  })

  window.addEventListener('scroll', () => {
    const body = document.querySelector('body')

    if (document.querySelector('html').scrollTop > 50 || document.querySelector('body').scrollTop > 50) {
      body.classList.add('body--scrolled')
      return
    }
    body.classList.remove('body--scrolled')
  })

  // Toggle currently visible tab
  document.querySelectorAll('.tabs label').forEach((tab) => {
    tab.addEventListener('click', function () {
      const target = this.dataset.target
      toggleActiveElement(this)
      toggleActiveElement(document.getElementById(target))
    })
  })
})
